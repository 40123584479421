import React, {Component} from 'react';
import {connect} from 'react-redux';
import './ip-hierarchy-levels.scss';
import {ReactComponent as PlusIcon} from 'assets/icons/plus.svg';
import {ReactComponent as MinusIcon} from 'assets/icons/minus.svg';
import {ReactComponent as ChevronRight} from 'assets/icons/chevron-right.svg';
import {ReactComponent as SearchIcon} from 'assets/icons/search.svg';
import {displayNotification} from '../../../actions/notifications';
import getIPList from "../../../actions/api/IPHierarchy/ip-list";
import getIPLevels from "../../../actions/api/IPHierarchy/ip-levels";
import {FormControl} from "react-bootstrap";

class IPHierarchyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IIPHierarchyList: [],
            IPHierarchyLevels: [],
            SelectedLevelBranchList: {},
            filterText: {},
            globalSearchText: '',
            searchResults: [],
            showSearchResults: false
        }
    };

    componentDidMount() {
        this.fetchIPHierarchy();
    }

    fetchIPHierarchy = () => {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};

        getIPList(organisation_id, organisation_id)
            .then((data) => {
                this.setState({IIPHierarchyList: data});
            })
            .catch(err => alert(err.message));

        getIPLevels(organisation_id, organisation_id)
            .then((data) => {
                data.forEach(item => {
                    item.expanded = true;
                });
                this.setState({IPHierarchyLevels: data});
            })
            .catch(err => alert(err.message));
    }

    hasChildren = (item) => {
        const {IIPHierarchyList} = this.state;
        return IIPHierarchyList.some(child => child.parent_ip_id === item.ip_id);
    };

    getItemHierarchy = (item) => {
        const {IIPHierarchyList} = this.state;
        let hierarchy = [];
        let currentItem = item;

        while (currentItem) {
            hierarchy.unshift(currentItem);
            currentItem = IIPHierarchyList.find(i => i.ip_id === currentItem.parent_ip_id);
        }

        return hierarchy;
    }

    handleGlobalSearch = (searchText) => {
        const {IIPHierarchyList} = this.state;
        this.setState({globalSearchText: searchText});

        if (!searchText) {
            this.setState({searchResults: [], showSearchResults: false});
            return;
        }

        const results = IIPHierarchyList.filter(item =>
            item.string_path.some(path =>
                path.toLowerCase().includes(searchText.toLowerCase())
            )
        ).map(item => ({
            ...item,
            hierarchy: this.getItemHierarchy(item)
        }));

        this.setState({
            searchResults: results,
            showSearchResults: true
        });
    }

    handleSearchResultSelect = (selectedItem) => {
        const hierarchyPath = selectedItem.hierarchy;
        const newSelectedBranches = {};

        hierarchyPath.forEach((item, index) => {
            newSelectedBranches[index] = item.ip_id;
        });

        this.setState({
            SelectedLevelBranchList: newSelectedBranches,
            globalSearchText: '',
            showSearchResults: false
        });
    }

    handleFilterChange = (levelId, text) => {
        this.setState(prevState => ({
            filterText: {
                ...prevState.filterText,
                [levelId]: text.toLowerCase()
            }
        }));
    }

    filteredItems = (levelItem, branchIndex) => {
        const {IIPHierarchyList, SelectedLevelBranchList, filterText} = this.state;

        const selectedItemId = SelectedLevelBranchList[branchIndex];
        const selectedItem = selectedItemId ?
            IIPHierarchyList.find(item => item.ip_id === selectedItemId) :
            null;

        let items = IIPHierarchyList.filter(item =>
            item.ip_level_id === levelItem.ip_level_id &&
            (branchIndex === 0 ? item.parent_ip_id === null : item.parent_ip_id === SelectedLevelBranchList[branchIndex - 1])
        );

        const filter = filterText[levelItem.ip_level_id];
        if (filter && filter.length > 0) {
            const filteredItems = items.filter(item =>
                item.string_path[item.string_path.length - 1].toLowerCase().includes(filter.toLowerCase())
            );

            // if (selectedItem &&
            //     items.includes(selectedItem) &&
            //     !filteredItems.includes(selectedItem)) {
            //     filteredItems.push(selectedItem);
            // }

            items = filteredItems;
        }

        return items.sort((a, b) => {
            const nameA = a.string_path[a.string_path.length - 1].toLowerCase();
            const nameB = b.string_path[b.string_path.length - 1].toLowerCase();
            return nameA.localeCompare(nameB);
        });
    }

    toggleExpand = (levelItem) => {
        this.setState(prevState => ({
            IPHierarchyLevels: prevState.IPHierarchyLevels.map(level =>
                level.ip_level_id === levelItem.ip_level_id
                    ? {...level, expanded: !level.expanded}
                    : level
            )
        }));
    }

    selectItem = (item, branchIndex) => {
        this.setState(prevState => ({
            SelectedLevelBranchList: {
                ...prevState.SelectedLevelBranchList,
                [branchIndex]: item.ip_id
            }
        }));
    }

    render() {
        const {
            IPHierarchyLevels,
            globalSearchText,
            searchResults,
            showSearchResults
        } = this.state;

        return (
            <div className='companyInfoBlock ip-hierarchy-list-display-builder'>
                <div className={'ip-hierarchy-header'}>
                    <h3>IP Hierarchy List</h3>

                    <div className="global-search-container">
                        <div className="search-input-wrapper">
                            <SearchIcon className="search-icon"/>
                            <FormControl
                                type="text"
                                placeholder="Search across all levels..."
                                value={globalSearchText}
                                onChange={(e) => this.handleGlobalSearch(e.target.value)}
                                className="global-search-input"
                            />
                        </div>
                        {showSearchResults && globalSearchText && (
                            <div className="search-results-dropdown">
                                {searchResults.length > 0 ? (
                                    searchResults.map((result) => (
                                        <div
                                            key={result.ip_id}
                                            className="search-result-item"
                                            onClick={() => this.handleSearchResultSelect(result)}
                                        >
                                            <div className="result-path">
                                                {result.hierarchy.map((item, index) => (
                                                    <span key={item.ip_id}>
                                                        {item.string_path[item.string_path.length - 1]}
                                                        {index < result.hierarchy.length - 1 && " > "}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-results-message">
                                        <p>No matching results found</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className={'level-branch-row'}>
                    {IPHierarchyLevels.map((levelItem, branchIndex) => (
                        <div key={levelItem.ip_level_id} className={'level-branch-col'}>
                            <div className={'level-item-cell'}>
                                <div
                                    className={`level-item-cell-name ${levelItem.expanded ? 'active' : ''}`}
                                    onClick={() => this.toggleExpand(levelItem)}
                                >
                                    <h4>{levelItem.level_name}</h4>
                                    <span>[{this.filteredItems(levelItem, branchIndex).length}]</span>

                                    <i className={'colapse-icon'}>
                                        {levelItem.expanded ? <MinusIcon/> : <PlusIcon/>}
                                    </i>
                                </div>

                                {levelItem.expanded && (
                                    <div className={`level-item-cell-list active`}>
                                        <div className={'pad-10'}>
                                            <div className={`filter-input-box`}>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Filter"
                                                    className="x-filter-bar-search-box email-filter-box"
                                                    value={this.state.filterText[levelItem.ip_level_id] || ''}
                                                    onChange={(e) => this.handleFilterChange(levelItem.ip_level_id, e.target.value)}
                                                />
                                                <span
                                                    className={`filter-cleaner`}
                                                    onClick={() => this.handleFilterChange(levelItem.ip_level_id, '')}>✕</span>
                                            </div>
                                        </div>
                                        <div className={"level-item-branch-wrapper"}>
                                            {this.filteredItems(levelItem, branchIndex).length > 0 ? (
                                                this.filteredItems(levelItem, branchIndex).map(item => (
                                                    <div
                                                        key={item.ip_id}
                                                        className={`level-item-branch ${this.state.SelectedLevelBranchList[branchIndex] === item.ip_id ? 'active' : ''}`}
                                                        onClick={() => this.selectItem(item, branchIndex)}
                                                    >
                                                        <span>{item.string_path[item.string_path.length - 1]}</span>
                                                        {this.hasChildren(item) && <i><ChevronRight/></i>}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no-data-message">
                                                    <p>No data available</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    {displayNotification}
)(IPHierarchyList);